import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import './servicelist.scss';

export default function ServiceList() {
  const serviceData = [
    {
      title: 'Windikate QR',
      subtitle:
        'Introducing QRX (QR eXperience), a versatile solution enabling users to effortlessly create static, dynamic, and blockchain-based QR codes without technical complexity. QRX streamlines QR code generation for diverse applications.',
      imgUrl: '/images/service_8.jpeg',
      href: 'https://windikate.com/qr',
    },
    {
      title: 'NOSE',
      subtitle:
        'Introducing NOSE (No Server Edition), a revolutionary blockchain solution that eliminates the need for server management, technical expertise, and complex setups. With NOSE, anyone can effortlessly access and utilize blockchain technology.',
      imgUrl: '/images/service_7.jpeg',
      href: 'https://nose.windikate.com',
    },
    {
      title: 'Attendance',
      subtitle:
        'Introducing ATTEND360, a comprehensive attendance management solution that simplifies tracking, recording, and analyzing attendance data without the need for complicated systems or technical expertise. ATTEND360 streamlines attendance processes for seamless management.',
      imgUrl: '/images/service_9.jpeg',
      href: '/service/service-details',
    },
    {
      title: 'Markup Bird',
      subtitle:
        'Introducing MARKUP BIRD, your ultimate SEO marketing companion, effortlessly creating schema markup for enhanced search visibility without the need for technical SEO expertise. MARKUP BIRD revolutionizes SEO marketing strategies with ease.',
      imgUrl: '/images/service_10.jpeg',
      href: '/service/service-details',
    },
  ];
  const [active, setActive] = useState(0);
  const handelActive = index => {
    setActive(index);
  };
  return (
    <Div className="cs-iconbox_3_list">
      {serviceData.map((item, index) => (
        <Div
          className={`cs-hover_tab ${active === index ? 'active' : ''}`}
          key={index}
          onMouseEnter={() => handelActive(index)}
        >
          <div onClick={()=>{window.location.href=item.href}} target='_blank' className="cs-iconbox cs-style3">
            <>
              <Div className="cs-image_layer cs-style1 cs-size_md">
                <Div className="cs-image_layer_in">
                  <img
                    src={item.imgUrl}
                    alt="Thumb"
                    className="w-100 cs-radius_15"
                  />
                </Div>
              </Div>
              <span className="cs-iconbox_icon cs-center">
                <svg
                  width={30}
                  height={29}
                  viewBox="0 0 30 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.3803 3.05172C29.4089 1.94752 28.537 1.02921 27.4328 1.00062L9.43879 0.534749C8.33459 0.506159 7.41628 1.37811 7.38769 2.48231C7.35911 3.58651 8.23106 4.50482 9.33526 4.53341L25.3299 4.94752L24.9158 20.9422C24.8872 22.0464 25.7592 22.9647 26.8634 22.9933C27.9676 23.0218 28.8859 22.1499 28.9144 21.0457L29.3803 3.05172ZM3.37714 28.5502L28.7581 4.4503L26.0039 1.54961L0.622863 25.6495L3.37714 28.5502Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <Div className="cs-iconbox_in">
                <h2 className="cs-iconbox_title">{item.title}</h2>
                <Div className="cs-iconbox_subtitle">{item.subtitle}</Div>
              </Div>
            </>
          </div>
        </Div>
      ))}
    </Div>
  );
}
